import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { usePageContext } from "@3nvi/gatsby-theme-intl"

import Menu from "./menu"
import Logo from "../images/logo-msi.png"
import i18n from '../../i18n.json'
import Seo from './seo'

const Header = ({ siteTitle }) => {
  const { supportedLanguages, originalPath, lang } = usePageContext()
  const socials = i18n[lang].translation.social
  const allSocial = Object.keys(socials).map(social =>
    <a href={socials[social].link}>
      <img src={socials[social].icon} alt="social icon"/>
    </a>  
  )
  return (
    <header className="mt-4">
      <Seo></Seo>
      <div >
        <div className="flex justify-between content-end">
          <div className="flex flex-row flex-wrap justify-center content-end gap-1">
            {supportedLanguages.map(supportedLang => (
              <GatsbyLink
                aria-label={`Change language to ${supportedLang}`}
                key={supportedLang}
                to={`/${supportedLang}${originalPath}`}
              >
                <img src={`/${supportedLang}.jpeg` } alt="flag"/>
              </GatsbyLink>
            ))}
          </div>
          <h1 style={{ margin: 0 }}>
            <GatsbyLink
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <img src={Logo} alt="MSI forks logo" />
            </GatsbyLink>
          </h1>
          <div className="flex flex-row flex-wrap justify-center content-end gap-1">
             { allSocial }
          </div>
        </div>
      </div>
      <Menu classes="bg-red w-100 border-white font-bold text-white my-2"></Menu>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
