import React from "react"
import { useTranslation } from "@3nvi/gatsby-theme-intl"
import { Link as GatsbyLink } from "gatsby"
import { usePageContext } from '@3nvi/gatsby-theme-intl';

const Menu = ({ classes }) => {
  const { lang } = usePageContext()
  const { t } = useTranslation()
  var international = 'msinternational'
  if (lang === 'br'){
    international = 'treinamento'
  }

  return (
    <nav
    className={classes}
    >
        <ul className="flex flex-row flex-wrap justify-center border-inherit text-inherit content-center">
            <li className="border-r-2 px-4 text-xl">
              <GatsbyLink
                to={`/${lang}/`} >
                  {t("menu.first")}</GatsbyLink>
            </li>
            <li className="border-r-2 px-4 text-xl">
             <GatsbyLink
                to={`/${lang}/company`} >
                  {t("menu.second")}</GatsbyLink>
            </li>
            <li className="border-r-2 px-4 text-xl">
              <GatsbyLink
                to={`/${lang}/fork-arms`} >
                  {t("menu.third")}</GatsbyLink>
            </li>
            <li className="border-r-2 px-4 text-xl">
              <GatsbyLink
                to={`/${lang}/${international}`} >
                  {t("menu.fourth")}</GatsbyLink>
            </li>
            <li className="border-r-2 px-4 text-xl">
              <GatsbyLink
                to={`/${lang}/downloads`} >
                  {t("menu.fifth")}</GatsbyLink>
            </li>
            <li className="px-4 text-xl">
              <GatsbyLink
                to={`/${lang}/contact`} >
                  {t("menu.sixth")}</GatsbyLink>
            </li>
        </ul>

    </nav>
  )
}

Menu.propTypes = {
}

Menu.defaultProps = {
}

export default Menu
