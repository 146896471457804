import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "@3nvi/gatsby-theme-intl"

import Menu from "./menu"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <div>
      <footer class="py-4" >
        <Menu classes="w-100 font-bold border-black"></Menu>
      </footer>
      <p style={{fontSize: '9px'}} className="text-xs text-center font-bold mt-2">{t("footer")}</p>
    </div>

  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer